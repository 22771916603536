import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const Info: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 73 74" fill="none" {...props}>
      <g filter="url(#prefix__filter0_d_6065_9895)" fill="#fff">
        <path d="M33.125 23.998a3 3 0 116 0 3 3 0 01-6 0zM33.125 35.998a3 3 0 016 0v12a3 3 0 01-6 0v-12z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.125 35.998c0-16.56 13.44-30 30-30 16.56 0 30 13.44 30 30 0 16.56-13.44 30-30 30-16.56 0-30-13.44-30-30zm6 0c0 13.23 10.77 24 24 24s24-10.77 24-24-10.77-24-24-24-24 10.77-24 24z"
        />
      </g>
      <defs>
        <filter
          id="prefix__filter0_d_6065_9895"
          x={2.125}
          y={5.998}
          width={68}
          height={68}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6065_9895"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_6065_9895"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

const MemoInfo = React.memo(Info);
export default MemoInfo;
