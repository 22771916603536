import { useOfflineContext } from "app/context/offline.context";
import { usePlayerContext } from "app/context/player.context";
import { useTranslate } from "app/hooks/lang";
import { useGoToPodcast } from "app/hooks/navigation.hook";
import { selectPlayerState } from "app/store/slices/player";
import { selectUserState } from "app/store/slices/user";
import { useAppSelector } from "app/store/store";
import { formatTimeWithUnits } from "app/utils/date";
import { db } from "app/utils/db";
import { getDateFnsLocale } from "app/utils/language";
import { downloadEpisode, DownloadProgress } from "app/utils/offline";
import clsx from "clsx";
import { format, parseISO } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { usePostPillowListenV2ProgressChapter_id } from "services-hooks/hooks";
import {
  PodcastChaptersProgress,
  PodcastChapter,
  Category,
} from "services-hooks/types";
import MemoCheckMark from "../icons/CheckMark";
import MemoDelete from "../icons/Delete";
import MemoInfo from "../icons/Info";
import MemoLoader from "../icons/Loader";
import MemoOfflineOutline from "../icons/OfflineOutline";
import MemoPlay from "../icons/Play";
import MemoPodcastMenu from "../icons/PodcastMenu";
import MemoWave from "../icons/Wave";
import { useErrorToast, useToast } from "../toast/toast.component";
import { Dropdown, DropdownOption } from "../ui/dropdown.component";
import styles from "./chapter-item.module.scss";

interface ChapterItemProps {
  progress: PodcastChaptersProgress | undefined;
  chapter: PodcastChapter;
  podcast: Category;
  index: number;
  downloadAvailable: boolean;
  startPlayback: (chapter: PodcastChapter, chapterIndex: number) => void;
  showOfflineAuthGuard: VoidFunction;
  redirectToPodcast?: boolean;
  authCallback?: VoidFunction;
  reloadProgress?: VoidFunction;
}
export const ChapterItem: React.FC<ChapterItemProps> = ({
  progress,
  chapter,
  podcast,
  index,
  downloadAvailable,
  startPlayback,
  showOfflineAuthGuard,
  redirectToPodcast,
  authCallback = () => {},
  reloadProgress = () => {},
}) => {
  const playerState = useAppSelector(selectPlayerState);
  const { session } = useAppSelector(selectUserState);
  const { isPlaying, isMediaLoading } = usePlayerContext();
  const { offlineChapters } = useOfflineContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const goToPodcast = useGoToPodcast();
  const t = useTranslate();
  const deleteToast = useToast({ content: t("offline.remove-success-toast") });
  const { mutateAsync: postChapterProgress } =
    usePostPillowListenV2ProgressChapter_id();
  const [downloadProgress, setDownloadProgress] = useState<DownloadProgress>({
    percentage: 0,
    progressMB: "0",
    total: 0,
  });
  const offlineErrorToast = useErrorToast({
    content: t("offline.save-error-toast"),
  });
  const offlineSuccessToast = useToast({
    content: t("offline.save-success-toast"),
  });
  const offlineStartToast = useToast({
    content: t("offline.save-in-progress-toast"),
  });
  const formatPublishDate = (dateString: string) => {
    try {
      const parsedDate = parseISO(dateString);
      return format(parsedDate, "d MMM yyyy", {
        locale: getDateFnsLocale(),
      });
    } catch (e) {
      console.error(e);
      return "";
    }
  };
  const downloadEpisodeClickHandler = (
    chapter: PodcastChapter,
    playbackProgressSeconds: number
  ) => {
    if (session?.user) {
      offlineStartToast();
      setIsDownloading(true);
      downloadEpisode(
        chapter,
        podcast,
        playbackProgressSeconds,
        updateProgress,
        () => {
          setDownloadProgress({
            percentage: 0,
            progressMB: "0",
            total: 0,
          });
          offlineSuccessToast();
          setIsDownloading(false);
        },
        () => {
          setDownloadProgress({
            percentage: 0,
            progressMB: "0",
            total: 0,
          });
          offlineErrorToast();
          setIsDownloading(false);
        }
      );
    } else {
      showOfflineAuthGuard();
    }
  };

  const updateProgress = (progressBytes: DownloadProgress) => {
    setDownloadProgress(progressBytes);
  };
  const offlineChapter = offlineChapters?.find(
    (offlineChapter) => offlineChapter.permalink === chapter.permalink
  );
  const hasBeenDownloaded = !!offlineChapter;
  const markChapterAsPlayed = useCallback(async () => {
    if (session?.user) {
      if (hasBeenDownloaded) {
        db.offlineChapters.update(chapter.permalink!, {
          isPlayed: false,
          playbackProgress: chapter.duration,
        });
      }
      try {
        await postChapterProgress({
          chapter_id: chapter.permalink!,
          requestBody: {
            finished: true,
            seconds: chapter.duration,
          },
        });
      } catch (e) {}
      reloadProgress();
    } else {
      authCallback();
    }
  }, [session?.user, hasBeenDownloaded, chapter.permalink]);
  const getDropdownOptions = (): DropdownOption[] => {
    return [
      {
        title: t("go-to-podcast"),
        onClick: () => {
          goToPodcast(`/${podcast.permalink}`);
        },
        icon: <MemoInfo className={styles.rotate_button} />,
        hidden: !redirectToPodcast,
      },
      {
        title: t("offline.download-episode"),
        onClick: () =>
          downloadEpisodeClickHandler(chapter, progress?.seconds || 0),
        icon: <MemoOfflineOutline className={styles.offline_icon} />,
        hidden: !downloadAvailable || hasBeenDownloaded,
      },
      {
        title: t("mark-as-played"),
        onClick: markChapterAsPlayed,
        icon: (
          <MemoCheckMark
            className={clsx(styles.rotate_button, styles.check_icon)}
          />
        ),
        hidden: offlineChapter?.isPlayed,
      },
      {
        title: t("delete"),
        onClick: () => {
          db.offlineChapters.delete(chapter.permalink!).then(() => {
            deleteToast();
          });
        },
        icon: <MemoDelete />,
        hidden: !hasBeenDownloaded,
      },
    ];
  };

  const optionsId = `${chapter.permalink}-options-button`;
  const isChapterSelected =
    playerState?.activeMedia?.podcast?.podcastChapter?.permalink ===
    chapter?.permalink;
  const isChapterPlaying = isChapterSelected && isPlaying;
  const isChapterLoading = isChapterSelected && isMediaLoading;
  const closeDropdownCallback = useCallback(
    () => setShowDropdown(false),
    [setShowDropdown]
  );
  return (
    <div
      className={clsx(styles.podcast_chapter_wrapper, {
        [styles.active]: isChapterSelected,
      })}
      data-navigate-row
    >
      <div
        className={clsx(styles.podcast_chapter_main)}
        data-navigate-item
        tabIndex={0}
        onClick={() => startPlayback(chapter, index)}
      >
        <div className={styles.cover_wrapper}>
          <img className={styles.cover} src={chapter.logo} alt={chapter.name} />
          <div className={styles.hover_overlay}>
            <div className={styles.svg_wrapper}>
              {isChapterLoading ? (
                <MemoLoader />
              ) : isChapterPlaying ? (
                <MemoWave />
              ) : (
                <MemoPlay />
              )}
            </div>
          </div>
        </div>
        <div className={styles.podcast_chapter}>
          <div className={styles.podcast_chapter_details}>
            <h3>{chapter.name}</h3>
            <p className={styles.podcast_chapter_description}>
              {chapter.summary || chapter.name}
            </p>
          </div>

          <div className={styles.podcast_chapter_seek_time}>
            <p>
              {formatPublishDate(chapter?.published || "")} -{" "}
              {formatTimeWithUnits(chapter.duration, t)}
            </p>
          </div>
          <div className={styles.podcast_chapter_seek_wrapper}>
            <div className={styles.podcast_chapter_seek_bar}>
              {isDownloading ? (
                <div
                  className={clsx(
                    styles.podcast_chapter_seek_bar_inner,
                    styles.podcast_chapter_progress_bar_inner
                  )}
                  style={{
                    width: `${downloadProgress.percentage}%`,
                  }}
                >
                  &nbsp;
                </div>
              ) : (
                <div
                  className={styles.podcast_chapter_seek_bar_inner}
                  style={{
                    width:
                      progress && chapter.duration
                        ? `${(progress.seconds / chapter.duration) * 100}%`
                        : undefined,
                  }}
                >
                  &nbsp;
                </div>
              )}
            </div>

            {isDownloading && (
              <div className={styles.text_download_progress}>
                {downloadProgress.progressMB}MB of {downloadProgress.total}MB
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        data-navigate-item
        tabIndex={0}
        id={optionsId}
        className={clsx(
          styles.podcast_chapter_controls_wrapper,
          styles.podcast_chapter_controls_more,
          {
            [styles.show_dropdown]: showDropdown,
          }
        )}
        onClick={() => setShowDropdown((showDropdown) => !showDropdown)}
      >
        <MemoPodcastMenu />
        <div className={styles.dropdown_wrapper}>
          <Dropdown
            open={showDropdown}
            onClose={closeDropdownCallback}
            id={chapter.permalink!}
            options={getDropdownOptions()}
          />
        </div>
      </div>
    </div>
  );
};
