import React from "react";
import styles from "./Wave.module.scss";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const Wave: React.FC<IconProps> = (props) => {
  return (
    <svg viewBox="0 0 50 38.05" width="1em" height="1em" {...props} className={styles.wave}>
      <path
        data-name="Line 1"
        className={styles.path}
        d="M.91 15H.78A1 1 0 000 16v6a1 1 0 102 0v-6a1 1 0 00-1-1H.91z"
      />
      <path
        data-name="Line 2"
        className={styles.path}
        d="M6.91 9h-.13A1 1 0 006 10v18a1 1 0 102 0V10a1 1 0 00-1-1h-.09z"
      />
      <path
        data-name="Line 3"
        className={styles.path}
        d="M12.91 0h-.13A1 1 0 0012 1v36a1 1 0 102 0V1a1 1 0 00-1-1h-.09z"
      />
      <path
        data-name="Line 4"
        className={styles.path}
        d="M18.91 10h-.12a1 1 0 00-.79 1v16a1 1 0 102 0V11a1 1 0 00-1-1h-.09z"
      />
      <path
        data-name="Line 5"
        className={styles.path}
        d="M24.91 15h-.12a1 1 0 00-.79 1v6a1 1 0 002 0v-6a1 1 0 00-1-1h-.09z"
      />
      <path
        data-name="Line 6"
        className={styles.path}
        d="M30.91 10h-.12a1 1 0 00-.79 1v16a1 1 0 102 0V11a1 1 0 00-1-1h-.09z"
      />
      <path
        data-name="Line 7"
        className={styles.path}
        d="M36.91 0h-.13A1 1 0 0036 1v36a1 1 0 102 0V1a1 1 0 00-1-1h-.09z"
      />
      <path
        data-name="Line 8"
        className={styles.path}
        d="M42.91 9h-.13a1 1 0 00-.78 1v18a1 1 0 102 0V10a1 1 0 00-1-1h-.09z"
      />
      <path
        data-name="Line 9"
        className={styles.path}
        d="M48.91 15h-.12a1 1 0 00-.79 1v6a1 1 0 102 0v-6a1 1 0 00-1-1h-.09z"
      />
    </svg>
  );
};

const MemoWave = React.memo(Wave);
export default MemoWave;
