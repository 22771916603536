import { useGoBackEvent } from "app/hooks/go-back.hook";
import { useArrowKeyNavigationOverlay } from "app/hooks/keyboard-navigation.hook";
import clsx from "clsx";
import React, { ReactElement, useCallback } from "react";
import { useClickAway } from "react-use";
import styles from "./dropdown.module.scss";

export interface DropdownOption {
  title: string;
  onClick: VoidFunction;
  icon: ReactElement;
  hidden?: boolean;
}

interface DropdownProps {
  id: string | number;
  options: DropdownOption[];
  onClose: VoidFunction;
  open: boolean;
}

export const Dropdown: React.FC<DropdownProps> = ({
  id,
  options,
  onClose,
  open,
}) => {
  const goBackCallback = useCallback(() => {
    onClose();
  }, [onClose]);
  useGoBackEvent("Dropdown", goBackCallback, open);

  const handleClick = (e: MouseEvent, option: DropdownOption) => {
    option.onClick();
    onClose();
    e.stopPropagation();
  };
  const wrapperRef = useArrowKeyNavigationOverlay(open);
  useClickAway(wrapperRef, (e) => {
    if (
      !e?.composedPath()?.filter((item) => (item as HTMLElement).id === id)
        .length
    ) {
      onClose();
    }
  });
  return (
    <div className={clsx(styles.wrapper)} ref={wrapperRef}>
      {options
        .filter((option) => !option.hidden)
        .map((option, index) => {
          return (
            <div key={`dropdown-${id}-${index}`} data-navigate-row={open}>
              <div
                data-navigate-item={open}
                tabIndex={0}
                className={styles.option}
                onClick={(e) => handleClick(e as any, option)}
              >
                <div className={styles.icon_wrapper}>{option.icon}</div>
                {option.title}
              </div>
            </div>
          );
        })}
    </div>
  );
};
