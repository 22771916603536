import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const Play: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 180 180" fill="none" {...props}>
      <path
        d="M131.617 83.25L80.955 44.5C75.392 40.228 67.5 44.27 67.5 51.289v77.422c0 7.018 7.892 11.061 13.455 6.789l50.662-38.75c4.51-3.28 4.51-10.144 0-13.5z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoPlay = React.memo(Play);
export default MemoPlay;
