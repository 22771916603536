import { getPillowPodcastchapterPlay } from "services-hooks/services";
import { Category, PodcastChapter } from "services-hooks/types";
import { db } from "./db";
import sendPodcastDownloadError from "./downloadErrorTracker";

export interface DownloadProgress {
  percentage: number;
  progressMB: string;
  total: number;
}
export const checkIfCanBeDownloaded = async (
  episode: PodcastChapter,
  successCallback: () => void,
  errorCallback?: () => void
) => {
  const { data: playData } = await getPillowPodcastchapterPlay(
    episode.permalink!
  );

  let currentStreamIndex = 0;
  const streamCount = playData?.body?.content?.streams?.length || 0;
  if (playData?.body?.content?.streams?.length) {
    const streamUrl = playData.body.content.streams[0].url!;
    const streams = playData.body.content.streams!;

    const handleXHRError = (xhr: XMLHttpRequest) => {
      xhr.abort();
      if (
        currentStreamIndex < streamCount - 1 &&
        streams[currentStreamIndex + 1].url
      ) {
        currentStreamIndex++;
        sendRequest(streams[currentStreamIndex]!.url!);
      } else if (errorCallback) {
        if (episode.podcastPermalink && episode.permalink) {
          sendPodcastDownloadError(episode.podcastPermalink, episode.permalink)
        }
        errorCallback();
      }
    };

    const sendRequest = (fileUrl: string) => {
      const xhr = new XMLHttpRequest();
      xhr.timeout = 2000;
      xhr.open("HEAD", fileUrl, true);
      xhr.addEventListener("timeout", () => handleXHRError(xhr));
      xhr.addEventListener("error", () => handleXHRError(xhr));
      xhr.addEventListener(
        "load",
        async () => {
          if ([200, 302, 304].includes(xhr.status)) {
            successCallback();
          } else {
            xhr.abort();
            if (
              currentStreamIndex < streamCount - 1 &&
              streams[currentStreamIndex + 1].url
            ) {
              currentStreamIndex++;
              sendRequest(streams[currentStreamIndex]!.url!);
            } else if (errorCallback) {
              if (episode.podcastPermalink && episode.permalink) {
                sendPodcastDownloadError(episode.podcastPermalink, episode.permalink)
              }
              errorCallback();
            }
          }
        },
        false
      );
      xhr.send();
    };

    sendRequest(streamUrl);
  }
};

export const downloadEpisode = async (
  episode: PodcastChapter,
  podcast: Category,
  playbackProgress: number,
  updateProgress?: (progress: DownloadProgress) => void,
  successCallback?: () => void,
  errorCallback?: () => void
) => {
  try {
    const { data: offlineData } = await getPillowPodcastchapterPlay(
      episode.permalink!
    );
    let podcastLogoBase64 = "";
    let chapterLogoBase64 = "";

    let podcastLogo = new Image();
    podcastLogo.crossOrigin = "Anonymous";
    podcastLogo.onload = () => {
      const canvas: HTMLCanvasElement = document.createElement(
        "CANVAS"
      ) as HTMLCanvasElement;
      const ctx = canvas.getContext("2d");
      canvas.height = podcastLogo.naturalHeight;
      canvas.width = podcastLogo.naturalWidth;
      ctx?.drawImage(podcastLogo, 0, 0);
      podcastLogoBase64 = canvas.toDataURL("image/jpeg");
    };
    podcastLogo.src = podcast.logo || podcast?.smallLogo || "";

    let chapterLogo = new Image();
    chapterLogo.crossOrigin = "Anonymous";
    chapterLogo.onload = () => {
      const canvas: HTMLCanvasElement = document.createElement(
        "CANVAS"
      ) as HTMLCanvasElement;
      const ctx = canvas.getContext("2d");
      canvas.height = chapterLogo.naturalHeight;
      canvas.width = chapterLogo.naturalWidth;
      ctx?.drawImage(chapterLogo, 0, 0);
      chapterLogoBase64 = canvas.toDataURL("image/jpeg");
    };
    chapterLogo.src = episode.logo || episode?.smallLogo || "";

    let currentStreamIndex = 0;
    const streamCount = offlineData?.body?.content?.streams?.length || 0;
    if (offlineData?.body?.content?.streams?.length) {
      const streamUrl = offlineData.body.content.streams[0].url!;
      const streams = offlineData.body.content.streams!;

      const sendRequest = (fileUrl: string) => {
        let fileBlob: Blob;

        const xhr = new XMLHttpRequest();
        xhr.open("GET", fileUrl, true);
        xhr.responseType = "blob";
        xhr.addEventListener("progress", (e) => {
          if (updateProgress) {
            const percentage = Math.round((e.loaded / e.total) * 100);
            const progressMB = Number(e.loaded / 1024 / 1024).toPrecision(3);
            const total = Math.round(e.total / 1024 / 1024);
            updateProgress({
              percentage,
              progressMB,
              total,
            });
          }
        });
        xhr.addEventListener("error", () => {
          xhr.abort();
          if (
            currentStreamIndex < streamCount - 1 &&
            streams[currentStreamIndex + 1].url
          ) {
            currentStreamIndex++;
            sendRequest(streams[currentStreamIndex]!.url!);
          } else if (errorCallback) {
            if (episode.podcastPermalink && episode.permalink) {
              sendPodcastDownloadError(episode.podcastPermalink, episode.permalink)
            }
            errorCallback();
          }
        });
        xhr.addEventListener(
          "load",
          async () => {
            if (xhr.status === 200) {
              fileBlob = xhr.response;

              const episodeCopy: PodcastChapter = JSON.parse(
                JSON.stringify(episode)
              );
              episodeCopy.logo = chapterLogoBase64;
              const podcastCopy = JSON.parse(JSON.stringify(podcast));
              podcastCopy.logo = podcastLogoBase64;

              try {
                const permalinkKey = await db.offlineChapters.add({
                  chapter: episodeCopy,
                  permalink: episode.permalink!,
                  podcast: podcastCopy,
                  file: fileBlob,
                  playbackProgress,
                  isPlayed: playbackProgress === episodeCopy.duration,
                });

                if (successCallback) {
                  successCallback();
                }
              } catch (dbError) {
                xhr.abort();
                if (
                  currentStreamIndex < streamCount - 1 &&
                  streams[currentStreamIndex + 1].url
                ) {
                  currentStreamIndex++;
                  sendRequest(streams[currentStreamIndex]!.url!);
                } else if (errorCallback) {
                  if (episode.podcastPermalink && episode.permalink) {
                    sendPodcastDownloadError(episode.podcastPermalink, episode.permalink)
                  }
                  errorCallback();
                }
              }
            } else {
              xhr.abort();
              if (
                currentStreamIndex < streamCount - 1 &&
                streams[currentStreamIndex + 1].url
              ) {
                currentStreamIndex++;
                sendRequest(streams[currentStreamIndex]!.url!);
              } else if (errorCallback) {
                if (episode.podcastPermalink && episode.permalink) {
                  sendPodcastDownloadError(episode.podcastPermalink, episode.permalink)
                }
                errorCallback();
              }
            }
          },
          false
        );
        xhr.send();
      };

      sendRequest(streamUrl);
    }
  } catch (e) {
    if (episode.podcastPermalink && episode.permalink) {
      sendPodcastDownloadError(episode.podcastPermalink, episode.permalink)
    }
    errorCallback && errorCallback();
  }
};
