export const formatTime = (seconds: number | undefined) => {
  if (seconds !== undefined) {
    let secondsDiff = seconds;
    const hoursDiff = Math.floor(secondsDiff / 3600);
    secondsDiff -= hoursDiff * 3600;
    const minutesDiff = Math.floor(secondsDiff / 60);
    secondsDiff -= minutesDiff * 60;
    secondsDiff |= 0;

    return `${hoursDiff.toString().padStart(2, "0")}:${minutesDiff
      .toString()
      .padStart(2, "0")}:${secondsDiff.toString().padStart(2, "0")}`;
  }
  return "";
};

export const formatTimeWithUnits = (seconds: number | undefined, t: Function) => {
  if (seconds !== undefined) {
    let secondsDiff = seconds;
    const hoursDiff = Math.floor(secondsDiff / 3600);
    secondsDiff -= hoursDiff * 3600;
    const minutesDiff = Math.floor(secondsDiff / 60);
    secondsDiff -= minutesDiff * 60;
    secondsDiff |= 0;
    let result = '';

    if (hoursDiff) {
      result += `${hoursDiff.toString().padStart(2, "0")} ${t('time.h')} `;
    }
    if (minutesDiff) {
      result += `${minutesDiff.toString().padStart(2, "0")} ${t('time.min')} `;
    }
    if (secondsDiff) {
      result += `${secondsDiff.toString().padStart(2, "0")} ${t('time.s')} `;
    }

    return result;
  }
  return "";
};
