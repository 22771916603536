import { LOCAL_STORAGE_COUNTRY_KEY } from "app/context/country.context";
import axios from "axios";

const sendPodcastDownloadError = (permalink: string, chapterPermalink: string) => {
  try {
    let ip = '';
    if (window) {
      const country = window.localStorage.getItem(LOCAL_STORAGE_COUNTRY_KEY);
      ip = country ? JSON.parse(country)?.ip : '';
    }
    axios({
      method: 'get',
      url: '/api/google-api/download-error',
      params: {
        data: {
          permalink,
          chapterPermalink,
          ip,
          date: new Date(),
        }
      }
    });
  } catch (e) {
    console.error(e);
  }
};

export default sendPodcastDownloadError;