import MemoOffline from "app/components/icons/Offline";
import { Button, ButtonText } from "../ui/button.component";
import styles from "./offline-auth-guard.module.scss";
import { Auth } from "./auth.component";
import { useCallback, useState } from "react";
import { useArrowKeyNavigationOverlay } from "app/hooks/keyboard-navigation.hook";
import { Portal } from "../ui/portal";
import useModalScroll from "app/hooks/modal-scroll.hook";
import { useTranslate } from "app/hooks/lang";
import { useGoBackEvent } from "app/hooks/go-back.hook";

interface OfflineAuthGuardProps {
  onClose?: VoidFunction;
}

export const OfflineAuthGuard: React.FC<OfflineAuthGuardProps> = ({
  onClose,
}) => {
  const t = useTranslate();
  const [showAuth, setShowAuth] = useState(false);
  const goBackCallback = useCallback(() => {
    setShowAuth(false);
  }, [setShowAuth]);
  useGoBackEvent("OfflineAuthGuard", goBackCallback, showAuth);
  return (
    <div>
      {showAuth ? (
        <Auth onClose={onClose} onSuccess={onClose} />
      ) : (
        <div className={styles.no_account}>
          <div className={styles.icon}>
            <MemoOffline />
          </div>
          <div className={styles.content}>
            <h3 className={styles.title}>{t("header.menu.offline")}</h3>
            <p className={styles.subtitle}>{t("you-dont-have-podcasts")}</p>
            <p className={styles.subtitle}>{t("to-downaload-episodes")}</p>
            <div data-navigate-row>
              <Button
                onClick={() => setShowAuth(true)}
                className={styles.button}
                data-navigate-item
              >
                {t("create-an-account")}
              </Button>
            </div>
            {onClose && (
              <>
                <br />
                <div data-navigate-row>
                  <ButtonText
                    data-navigate-item
                    onClick={() => onClose()}
                    className={styles.button}
                  >
                    {t("close")}
                  </ButtonText>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

interface OfflineAuthGuardPopupProps {
  onClose: VoidFunction;
}

export const OfflineAuthGuardPopup: React.FC<OfflineAuthGuardPopupProps> = ({
  onClose,
}) => {
  const wrapperRef = useArrowKeyNavigationOverlay();
  useModalScroll();
  useGoBackEvent("OfflineAuthGuardPopup", () => {
    onClose();
  });
  return (
    <Portal>
      <div className={styles.wrapper} ref={wrapperRef}>
        <OfflineAuthGuard onClose={onClose} />
      </div>
    </Portal>
  );
};
