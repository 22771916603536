import { usePlayerContext } from "app/context/player.context";
import { playerActions, selectPlayerState } from "app/store/slices/player";
import { useAppDispatch, useAppSelector } from "app/store/store";
import { Category, PodcastChapter } from "services-hooks/types";

export const usePlayback = () => {
  const { setPlayerEnabled, setIsPlaying, setFullscreenPlayerInitialyOpen } =
    usePlayerContext();
  const dispatch = useAppDispatch();
  const playerState = useAppSelector(selectPlayerState);

  const startChapterPlayback = (
    chapter: PodcastChapter,
    index: number,
    chapterList: PodcastChapter[],
    podcast: Category
  ) => {
    setFullscreenPlayerInitialyOpen(true);
    setPlayerEnabled(true);
    if (
      playerState?.activeMedia?.podcast?.podcastChapter?.permalink !==
      chapter?.permalink
    ) {
      dispatch(
        playerActions.selectTrack({
          category: null,
          podcast: { podcast: podcast, podcastChapter: chapter },
        })
      );
    } else {
      setIsPlaying(true);
    }
    dispatch(
      playerActions.setPodcastQueue({
        items: chapterList,
        activeIndex: index,
      })
    );
  };
  const stopPlayback = () => {
    setIsPlaying(false);
  };

  return { startChapterPlayback, stopPlayback };
};
