import * as React from "react"
import { SVGProps, memo } from "react"

const CheckMark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em" height="1em" viewBox="0 0 27 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.582 20.027 10.13-13.021 1.54 1.981-11.67 15.003-6.222-7.997 1.542-1.981 4.68 6.015Z"
      fill="#fff"
    />
  </svg>
)

const MemoCheckMark = memo(CheckMark)
export default MemoCheckMark
