import * as React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const PodcastMenu: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 1054 1054" fill="none" {...props}>
      <g opacity={0.1} fill="#fff">
        <path d="M1054 527c0 291.054-235.946 527-527 527S0 818.054 0 527 235.946 0 527 0s527 235.946 527 527z" />
        <path d="M1054 527c0 291.054-235.946 527-527 527S0 818.054 0 527 235.946 0 527 0s527 235.946 527 527z" />
      </g>
      <path
        d="M593.803 311.747c0 32.626-26.755 59.381-59.38 59.381v29.69c49.022 0 89.07-40.048 89.07-89.071h-29.69zm-59.38-59.38c32.625 0 59.38 26.755 59.38 59.38h29.69c0-49.022-40.048-89.07-89.07-89.07v29.69zm-59.381 59.38c0-32.625 26.755-59.38 59.381-59.38v-29.69c-49.023 0-89.071 40.048-89.071 89.07h29.69zm59.381 59.381c-32.626 0-59.381-26.755-59.381-59.381h-29.69c0 49.023 40.048 89.071 89.071 89.071v-29.69zm-59.381 163.295c0-32.625 26.755-59.38 59.381-59.38v-29.69c-49.023 0-89.071 40.048-89.071 89.07h29.69zm59.381 59.381c-32.626 0-59.381-26.755-59.381-59.381h-29.69c0 49.023 40.048 89.071 89.071 89.071v-29.69zm59.38-59.381c0 32.626-26.755 59.381-59.38 59.381v29.69c49.022 0 89.07-40.048 89.07-89.071h-29.69zm-59.38-59.38c32.625 0 59.38 26.755 59.38 59.38h29.69c0-49.022-40.048-89.07-89.07-89.07v29.69zm0 192.986c-49.023 0-89.071 40.047-89.071 89.07h29.69c0-32.625 26.755-59.38 59.381-59.38v-29.69zm89.07 89.07c0-49.023-40.048-89.07-89.07-89.07v29.69c32.625 0 59.38 26.755 59.38 59.38h29.69zm-89.07 89.07c49.022 0 89.07-40.047 89.07-89.07h-29.69c0 32.625-26.755 59.38-59.38 59.38v29.69zm-89.071-89.07c0 49.023 40.048 89.07 89.071 89.07v-29.69c-32.626 0-59.381-26.755-59.381-59.38h-29.69z"
        fill="#C7C7C7"
      />
    </svg>
  );
}

const MemoPodcastMenu = React.memo(PodcastMenu);
export default MemoPodcastMenu;
