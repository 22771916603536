import * as React from "react";

function Delete(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path fill="none" d="M0 0h256v256H0z" />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={20}
        d="M200 56L56 200M200 200L56 56"
      />
    </svg>
  );
}

const MemoDelete = React.memo(Delete);
export default MemoDelete;
